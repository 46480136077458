/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')

// import { initThreeV3 } from './init-three-v3';

// import { setupTransitionAnimations, runTransitionAnimations } from './transitions';

// import * as Turbo from "@hotwired/turbo";
import { initSlider } from '../slider';
import { initBookPageSlider } from '../slider';
// import PDFObject from "pdfobject";
// setupTransitionAnimations();

// const scrollToAnchor = (aid) => {
//   var aTag = $("#"+ aid);
//   $('html,body').animate({scrollTop: aTag.offset().top},'slow');
// }
let currentSlider = null;

document.addEventListener("DOMContentLoaded", function() {
  if(document.querySelector('.home .swiper-container')){
    initSlider();
  }
  if(document.querySelector('.books.show .swiper-container')){
    currentSlider = initBookPageSlider();
  }

  // initTina();

  // runTransitionAnimations();

  // document.querySelector(".back-to-top").addEventListener('click', function(){
  //   $('html,body').animate({ scrollTop: 0 }, 'slow');
  //   return false;
  // });

  document.querySelector(".burger-icon").addEventListener('click', function(){
    const burger = $(this);
    burger.toggleClass("menu-open");
    $('.mobile-menu-container').toggleClass("menu-open");
    $('body').toggleClass("menu-open");
    $('.burger-icon .burger-text').text(
      burger.hasClass('menu-open') ? "Κλείσιμο" : "Μενού"
    )
  });

  // const bookPreview = document.querySelector(".swiper-slide-active .book-preview");
  // if(bookPreview){
  //   document.querySelector(".swiper-slide-active .preview-btn").addEventListener('click', function(){
  //     bookPreview.classList.toggle('book-opened');
  //     setTimeout(()=>{
  //       currentSlider.updateAutoHeight(500);
  //     }, 500);
  //     return false;
  //   });
  // }

  const sortOptions = document.querySelectorAll('.sort .dropdown-item');
  if(sortOptions) {
    Array.from(sortOptions).forEach(function(element) {
      element.addEventListener('click', (ev) => {
        window.location.href = ev.target.href;
      });
    });
  }

  // const pdfViewer = document.querySelector('.swiper-slide-active .pdf-viewer');
  // if (pdfViewer) {
  //   const pdfUrl = document.querySelector('.swiper-slide-active .pdf-viewer').dataset.pdf;
  //   PDFObject.embed(pdfUrl, ".swiper-slide-active .pdf-viewer", { pdfOpenParams: { view: 'FitH' } });
  // }

  // if(window.location.hash === "#preview") {
  //   scrollToAnchor('preview');
  // }

  console.log('Page loaded!');
});

// document.addEventListener("turbo:load", function(){
//   console.log("turbo load");

// });

// // document.addEventListener("turbo:before-render", function(){
// //   console.log("turbo before render");
// // });

// document.addEventListener("turbo:before-cache", function(){
//   console.log("turbo before cache");
// });

// document.addEventListener("DOMContentLoaded",function(){
//   window.Turbo = Turbo;
// });





